import Vue from 'vue'
import App from "./App.vue";
import router from "@/route";
import "@/styles/index.scss"; // global css
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import MyC from 'dragonlab-component/lib/dragonLab-Component.umd.js'
import 'dragonlab-component/lib/dragonLab-Component.css'

Vue.use(MyC)
// console.log(MyC)
locale.use(lang)
Vue.use(ElementUI)
Vue.config.productionTip = false
window.Vue = Vue
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
 