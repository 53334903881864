import Vue from 'vue'
import VueRouter from 'vue-router'
import {MessageBox} from 'element-ui';
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
    children: [
      {
        path: "/overview",
        name: "Overview",
        component: () => import("@/views/OverView.vue"),
      },
      {
        path: "/",
        name: "Home",
        redirect:'/top'
      },
      {
        path: "/flow_view/",
        name: "FlowView",
        component: () => import("@/views/FlowView.vue"),
      },
      {
        path: "/top",
        name: "Top",
        component: () => import("@/views/DataUsage.vue"),
      },
      {
        path:'/app_view/:appName',
        name:"AppView",
        component: () => import("@/views/AppView.vue"),
      },
      {
        path:'/app_view/',
        name:"AppView",
        component: () => import("@/views/AppView.vue"),
      },
      {
        path:'/client_view/:clientIp',
        name:"ClientView",
        component: () => import("@/views/ClientView.vue"),
      },
      {
        path:'/client_view/',
        name:"ClientView",
        component: () => import("@/views/ClientView.vue"),
      },
      {
        path:'/anomaly/',
        name:"Anomaly",
        component: () => import("@/views/Anomaly.vue"),
      },
      {
        path:'/users/',
        name:"Uers",
        component: () => import("@/views/UserList.vue"),
      },
      {
        path:'/remote/',
        name:"Remote",
        component: () => import("@/views/Remote.vue"),
      },
      {
        path: '/document/:name',
        name: 'Document',
        component: () => import('@/views/Doc.vue'),
      },
      
    ],
  },
  
];

// 创建router实例
const router = new VueRouter({
  routes
})

router.beforeEach((to, from,next) => {
  const needLoginPages = ['FlowView','AppView','ClientView','Anomaly']
  // const needLoginPages = []
  const userInfo = localStorage.getItem('userInfo')
  if(!userInfo && needLoginPages.findIndex(name => name === to.name) !== -1){
    MessageBox('Need login first','INFO')
    router.push({ path: '/top'})
  }
  next()
})
export default router;
