<template>
  <div id="app">
    <router-view v-if="isRouterAlive" :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量
    // console.debug('this.reload', this)
    return {
      reload: this.reload,
    }
  },
  mounted() {
    localStorage.removeItem('selectedTime')
    localStorage.removeItem('dateZone')
    localStorage.removeItem('lastHour')
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
